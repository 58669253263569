import useAryLayoutType from 'framework/base/hooks/useAryLayoutType';
import React, { useEffect, useMemo } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  ApiAuthorizationConstants,
  AuthRouteGuard as AuthGuard,
  LoginCallbackHandler
} from 'spec';

import './assets/fontYekanBakh.css';
import './language/translation';
import { useAuth } from 'framework/base/authorization/AuthorizationProvider';
import appRoutes from './routes';

const { ApplicationPaths } = ApiAuthorizationConstants;

function lazyRoutes(routes, layoutType) {
  if (!routes || routes.length === 0) return null;
  return (
    <>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AuthGuard
              anonymousAccess={route.anonymousAccess}
              authKeys={route.authKeys}
              acceptedRole={route.acceptedRole}
              path={route.path}
              component={
                layoutType === 'mobile' && route.mobile
                  ? route.mobile
                  : route.component
              }
            />
          }
        >
          {lazyRoutes(route.routes, layoutType)}
        </Route>
      ))}
    </>
  );
}

const getDefaultRoute = (roles) => {
  if (roles?.some((role) => role === 'admin' || role === 'support')) {
    return '/dashboard/my-tickets';
  }
  if (roles?.some((role) => role === 'featureSupport')) {
    return '/dashboard/pending-operator';
  }
  if (roles?.some((role) => role === 'fmcgSupport')) {
    return '/dashboard/pending-purchases';
  }
  if (roles?.some((role) => role === 'rahmaniSupport')) {
    return '/dashboard/afghanestan-net';
  }
  return '/dashboard';
};

const App = () => {
  const { t } = useTranslation();
  const layoutType = useAryLayoutType();
  const auth = useAuth();
  const role = auth?.Role || [];
  const navigate = useNavigate();
  const location = useLocation();

  const defaultRoute = useMemo(() => getDefaultRoute(role), [role]);

  useEffect(() => {
    if (role.length > 0 && location.pathname === '/') {
      navigate(defaultRoute, { replace: true });
    }
  }, [role, defaultRoute, location.pathname]);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`%s | ${t('Ariyana')}`}
        defaultTitle={`${t('Ariyana')} - ${t(
          'Support And Software Development Panel'
        )}`}
      />
      <Routes>
        {lazyRoutes(appRoutes, layoutType)}
        <Route
          exact
          path={ApplicationPaths.LoginCallback}
          element={<LoginCallbackHandler />}
        />
      </Routes>
    </HelmetProvider>
  );
};

export default App;
